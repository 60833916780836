const keywordsData = [
  {
    id: 1,
    keyword: " ",
    keywordName : "All",
  },
  {
    id: 2,
    keyword: 'Regional',
    keywordName : "Regional App",
  },
  {
    id: 3,
    keyword: 'Categorical',
    keywordName : "Categorical App",
  },
  {
    id: 4,
    keyword: 'City',
    keywordName : "City App",
  },
  {
    id: 5,
    keyword: 'Social',
    keywordName : "Social App",
  },
  {
    id: 6,
    keyword: 'NRI',
    keywordName : "NRI App",
  }
  
];

export default keywordsData;
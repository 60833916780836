
function HappyStories() {
  return (

      <div className="container mx-auto p-6">
           <div className="max-w-2xl mx-auto p-4 bg-white shadow-lg rounded-lg">
    <p className="text-base text-gray-700 mb-4">
        ભૂદેવ નેટવર્ક વિવાહ સંસ્થા, અમદાવાદ - વડોદરા મા based એક ગવર્નમેન્ટ રેજીસ્ટર્ડ Socio-Commercial સંસ્થા છે. (Gov. Reg. # AHMD/120129).
    </p>
    <p className="text-base text-gray-700 mb-4">
        ભૂદેવ નેટવર્ક છેલ્લા 4 વર્ષ થી સતત કાર્યરત છે. અને, સમસ્ત બ્રાહ્મણ - ભૂદેવ લગ્નેચ્છુક ઉમેદવારો ના વિવાહ લગ્ન હેતુ,
    </p>
    <p className="text-base text-gray-700 mb-4">
        # 21 ભૂદેવ જીવનસાથી પરિચય સંમેલન નું આયોજન <br />
        # 22 પ્રકાર ની ભૂદેવ વિવાહ વેબસાઈટ નું નિર્માણ<br />
        # 350+ Free Vivah Groups અને,<br />
        # 100+ Premium Paid વિવાહ વૉટ્સએપ ગ્રુપ્સ
    </p>
    <p className="text-base text-gray-700 mb-4">
        ... નું નિર્માણ કર્યું છે. જેમાં, ગુજરાત, ભારત સહીત વિશ્વ ના 35+ દેશો ના લગભગ 1 lakh થી વધુ ભૂદેવ લગ્નેચ્છુક ઉમેદવારો એ લાભ લીધો છે.
    </p>
    <p className="text-base text-gray-700 mb-4">
        દર મહિને ભૂદેવ નેટવર્ક સંસ્થા ની 22 વિવાહ વેબસાઈટ અને અનેક પરિચય મેળા ની બુકલેટ /PDF થકી આશરે 400-500 થી વધૂ ભૂદેવ ઉમેદવારો ના વિવાહ થાય છે.
    </p>

    <p className="text-base text-gray-700 mb-4">
        આપ સૌનો સાથ-સહકાર આમજ કાયમ રહે એવી અમારી આખી team ની સૌને નમ્ર વિનંતી છે. 🙏
    </p>
    <p className="text-base text-gray-700 mb-4">
        આભાર સહ, 🙏<br />
        શ્રી વિપુલભાઈ પંડ્યા<br />
        ભૂદેવ નેટવર્ક - પ્રેસિડેન્ટ,<br />
        MD - Employa Technologies <br />
        વડોદરા | અમદાવાદ<br />
        9081522111,&nbsp;7990208986
    </p>
</div>

        </div>
    
      );
}

export default HappyStories;

import React from 'react'

export default function MessageBox() {
  return (
    <div><div className="max-w-7xl mx-auto mb-5 p-4 bg-white shadow-lg rounded-lg">
    <h3 className="text-lg font-semibold text-gray-800 border-b pb-2">🔗 <strong>ભૂદેવ નેટવર્ક Social Links :</strong></h3>
    <div className="space-y-4 mt-4">
        <p className="text-gray-600">ભૂદેવ નેટવર્ક Social Website : <a href="BhudevNetwork.com" className="text-blue-500 hover:text-blue-600">BhudevNetwork.com</a></p>
        <p className="text-gray-600">ભૂદેવ નેટવર્ક Facebook Group : (35000+ Members)<br />
            <a href="https://www.facebook.com/groups/bhudevnetwork/?ref=share_group_link" className="text-blue-500 hover:text-blue-600">https://www.facebook.com/groups/bhudevnetwork/?ref=share_group_link</a>
        </p>
        <p className="text-gray-600">YouTube Channel : 
            <a href="https://youtube.com/@bhudevnetwork9532?si=BfUQ2IBeHNawEhfG" className="text-blue-500 hover:text-blue-600">https://youtube.com/@bhudevnetwork9532?si=BfUQ2IBeHNawEhfG</a>
        </p>
        <p className="text-gray-600">Instagram Link : 
            <a href="https://instagram.com/bhudevnetwork?igshid=OGY3MTU3OGY1Mw==" className="text-blue-500 hover:text-blue-600">https://instagram.com/bhudevnetwork?igshid=OGY3MTU3OGY1Mw==</a>
        </p>
        <p className="text-gray-600">Kutumb App Link : 
            <a href="https://kutumb.app/bhudev-network?ref=KTO3V&amp;screen=id_card_section" className="text-blue-500 hover:text-blue-600">https://kutumb.app/bhudev-network?ref=KTO3V&amp;screen=id_card_section</a>
        </p>
    </div>

    <h2 className="text-xl font-bold text-gray-800 mt-6">WhatsApp Groups :</h2>
    <div className="space-y-4 mt-4">
        <p className="text-gray-600">Vivah Biodata Free Group : 
            <a href="https://chat.whatsapp.com/L6U2kzwFmIbB04xSSw202K" className="text-blue-500 hover:text-blue-600">https://chat.whatsapp.com/L6U2kzwFmIbB04xSSw202K</a>
        </p>
        <p className="text-gray-600">Jivansathi Parichay Mela Group : 
            <a href="https://chat.whatsapp.com/GYHaGjMjx672pTHwUT1oCM" className="text-blue-500 hover:text-blue-600">https://chat.whatsapp.com/GYHaGjMjx672pTHwUT1oCM</a>
        </p>
        <p className="text-gray-600">Jobs Free Group : 
            <a href="https://chat.whatsapp.com/KP4sbqnlZs62hrbem3tL6o" className="text-blue-500 hover:text-blue-600">https://chat.whatsapp.com/KP4sbqnlZs62hrbem3tL6o</a>
        </p>
    </div>
</div></div>
  )
}

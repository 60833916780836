import Body from "./body";
import ContactPage from "./contact";

function HomePage() {
  return (
   <>
      <Body />
      
   </>
  );
}

export default HomePage;

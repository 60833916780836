import React, { useState, useEffect } from 'react';
import CardData from "./card-data.js";
import './css/body.css';
import keywordsData from './keywordsData.js';
import MessageBox from './messagebox.js';


function Body() {
  const [sortOrder] = useState('asc'); // 'asc' or 'desc'
  const [keyword, setKeyword] = useState('');
  const [filteredData, setFilteredData] = useState([]);


  useEffect(() => {
    let sortArray = [...CardData];

    // Filter based on the keyword
    if (keyword.trim() !== '') {
      sortArray = sortArray.filter(item =>
        item.title.toLowerCase().includes(keyword.toLowerCase()) || 
        item.description.toLowerCase().includes(keyword.toLowerCase()) ||
        item.category.toLowerCase().includes(keyword.toLowerCase())
      );
    }
    
    setFilteredData(sortArray);
  }, [sortOrder, keyword]);

  const CardElements = filteredData.map(
    SingleCard => 
    <>
    <div className="flex p-5 flex-col bg-white rounded-lg shadow-md card-size gap-5" key = {SingleCard}>
      <div className='flex gap-4'>
        <div className="">
          <div className='flex'>
            <img src={SingleCard.image} alt="Placeholder" className="fixed-width" />
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex justify-between items-start">
            <span className="text-xs font-semibold inline-block py-1 px-2 text-teal-600 bg-gray-200">
              {SingleCard.category}
            </span>
          </div>
          <h2 className="text-base font-bold mt-2">{SingleCard.title}</h2>
          <p className="text-sm text-gray-700 ">
            {SingleCard.description}
          </p>
          
        </div>
        </div>
        <div className="flex">
            <a href={SingleCard.downloadUrl} className="bg-blue-500 hover:bg-blue-700 text-sm	text-white font-bold py-2 px-4 rounded" download="BhudevNetworkVivha">
              Download App
            </a>
            <a target='_blank' rel="noreferrer" href={SingleCard.url} className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 text-sm rounded">
              Open
            </a>
          </div>
      </div>
    </>
  );

  const KeywordElements = keywordsData.map(
    SingleKeyword =>
    <>

      <button
              className={`p-2 text-xs rounded border transition-colors duration-200 
              ${keyword === SingleKeyword.keyword ? 'bg-blue-500 text-white' : 'bg-amber-300 text-gray-700'}
              font-bold py-2 px-4 rounded`} 
            value={SingleKeyword.keyword}
            onClick={() => setKeyword(SingleKeyword.keyword)}
            >{SingleKeyword.keywordName}
      </button>
    </>
  );

    return (
      <>
         
        <div className="flex justify-between items-center bg-gray-200 p-4 rounded-lg mb-4 flex-wrap">
          <div className="flex gap-4">
            <label className="text-xs	text-xs	font-medium flex justify-center items-center" htmlFor="filter">Search: </label>
            <input 
              id="filter"
              type="text" 
              className="p-2 rounded border text-xs"
              placeholder="Enter keyword" 
              value={keyword} 
              onChange={(e) => setKeyword(e.target.value)} 
            />
          </div>
          <label className="text-xs font-medium flex justify-center items-center" htmlFor="filter">Total: {filteredData.length}</label>
        </div>
        <div className="flex flex-wrap justify-between p-2 items-center rounded-lg mb-4">
          <div className="flex gap-1 flex-wrap">
            {KeywordElements}
          </div>
        </div>
        {keyword === "Social" && (
        <div className="message-box">
          <MessageBox/>
        </div>
)}
        <div className="flex flex-wrap justify-center gap-4">
          
          
          {CardElements}
        </div>
      </>
    );
    
}

export default Body;


import React from 'react'

export default function ContactPage() {
  return (
    <div className="container mx-auto p-6">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-5">
        <div className='pb-5'>
        <h1 className='text-xl'><b>સમસ્ત બ્રાહ્મણ</b></h1>
        ભૂદેવ જીવનસાથી પરિચય સંમેલન ની લેટેસ્ટ બાયોડેટા બુકલેટ Eg.
        <br />
        (1) NRI booklet ,<br />
        (2) Gujarat booklet,<br />
        (3) Saurashtra,<br />
        (4) Mumbai-Maharashtra,<br />
        (5) Divorce-Widow,<br />
        (6) Doctor-Medical<br />

          etc મેળવવા or NRI , Gujarat, Doctor, Divorce, Masters/PhD , etc Vivah Website Registration માટે નીચે આપેલ એડ્રેસ અને નંબર ઉપર સંપર્ક કરવો.
        </div>
        {/* make coloum in mobile */}
        <div className='flex flex-col md:flex-row'>
          <div className='flex flex-1 flex-col p-4'>
            <p><h2 className='text-2xl'>અમદાવાદ (નવરંગપુરા):</h2><br />ભૂદેવ નેટવર્ક ટ્રસ્ટ ઓફિસ<br />A-703, નર-નારાયણ કોમ્પ્લેક્સ, નવરંગપુરા પોસ્ટ ઓફિસ ની સામે, સ્વસ્તિક ચાર રસ્તા ની પાસે, નવરંગપુરા, અમદાવાદ - 380009 // M # 9499701462 , 9016992328, 6355414702, 6352348949, 6355424206 <br />Office Timings : Monday to Sunday (all 7 days) : 10 AM to 6 PM</p>
          </div>
          <div className='flex flex-1 flex-col p-4'>
            <p><h2 className='text-2xl'>વડોદરા (સયાજીગંજ) :</h2><br />ભૂદેવ નેટવર્ક ટ્રસ્ટ ઓફિસ<br />603 - 6th floor, ગાલાવ ચેમ્બર્સ, ડેરી ડેન સર્કલ, સયાજીગંજ, વડોદરા 390020,<br />M : 9081522111, 9099798986, 9081042425, 7990208986, 9099828986, 9265307465, 9537784694, 9104608986<br />Office Timings : Monday to Sunday (all 7 days)&nbsp;:&nbsp;10&nbsp;AM&nbsp;to&nbsp;6&nbsp;PM</p>
          </div>
          <div className='flex flex-1 flex-col p-4'>
            <p><h2 className='text-2xl'> આણંદ(સરદારગંજ ઓફિસ)</h2>
              <p>ભૂદેવ નેટવર્ક ટ્રસ્ટ ઓફિસ</p>
              <p>FF/5 કન્હા સ્પર્શ કોમ્પ્લેક્સ, પેહલા માળે, સ્વામિનારાયણ સોસાયટી ની બાજુમાં, સરદાર ગંજ રોડ, આણંદ મોબાઇલ : 9099798986 , 7990208986</p>
            </p>
          </div>
      
        </div>
      </div>

    </div>
  )
}

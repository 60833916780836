const CardData = [
  {
    id: 1,
    title: "Bhudev Network Vivah",
    description: "BhudevNetworkVivah.com",
    url: "https://bhudevnetworkvivah.com/home/",
    image: "https://bhudevnetworkvivah.com/uploads/favicon/favicon_1669388175.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Regional",
  },
  {
    id: 2,
    title: "NRI Vivah",
    description: "NRI.BhudevNetworkVivah.com",
    url: "https://nri.BhudevNetworkVivah.com",
    image: "https://nri.bhudevnetworkvivah.com/uploads/favicon/favicon_1619188661.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Regional",
  },
  {
    id: 3,
    title: "Saurashtra Vivah",
    description: "Saurashtra.BhudevNetworkVivah.com",
    url: "http://Saurashtra.BhudevNetworkVivah.com",
    image: "https://saurashtra.bhudevnetworkvivah.com/uploads/favicon/favicon_1631249757.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Regional",
  },
  {
    id: 4,
    title: "Maharastra Vivah",
    description: "Maharashtra.BhudevNetworkVivah.com",
    url: "https://maharashtra.bhudevnetworkvivah.com",
    image: "https://maharashtra.bhudevnetworkvivah.com/uploads/favicon/favicon_1633782321.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Regional",
  },
  // http://NG.BhudevNetworkVivah.com

  {
    id: 22,
    title: "North Gujarat Vivah",
    description: "NG.BhudevNetworkVivah.com",
    url: "https://ng.bhudevnetworkvivah.com",
    image: "https://ng.bhudevnetworkvivah.com/uploads/favicon/favicon_1692183781.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Regional",

  },



  {
    id: 5,
    title: "Masters Vivah",
    description: "Masters.BhudevNetworkVivah.com",
    url: "https://masters.bhudevnetworkvivah.com",
    image: "https://masters.bhudevnetworkvivah.com/uploads/favicon/favicon_1679314996.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 6,
    title: "Doctor Vivah",
    description: "Doctor.BhudevNetworkVivah.com",
    url: "https://doctor.bhudevnetworkvivah.com",
    image: "https://doctor.bhudevnetworkvivah.com/uploads/favicon/favicon_1619608200.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 7,
    title: "Divorce Vivah",
    description: "Divorce.BhudevNetworkVivah.com",
    url: "https://divorce.bhudevnetworkvivah.com",
    image: "https://divorce.bhudevnetworkvivah.com/uploads/favicon/favicon_1619605420.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 8,
    title: "CACS Vivah",
    description: "CACS.BhudevNetworkVivah.com",
    url: "https://cacs.bhudevnetworkvivah.com",
    image: "https://cacs.bhudevnetworkvivah.com/uploads/favicon/favicon_1669389712.jpg",  
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 9,
    title: "GovJob Vivah",
    description: "GovJob.BhudevNetworkVivah.com",
    url: "https://govjob.bhudevnetworkvivah.com",
    image: "https://govjob.bhudevnetworkvivah.com/uploads/favicon/favicon_1669716695.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 10,
    title: "40plus Vivah",
    description: "40plus.BhudevNetworkVivah.com",
    url: "https://40plus.bhudevnetworkvivah.com",
    image: "https://40plus.bhudevnetworkvivah.com/uploads/favicon/favicon_1663850333.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 11,
    title: "1012 Vivah",
    description: "1012.BhudevNetworkVivah.com",
    url: "https://1012.bhudevnetworkvivah.com",
    image: "https://1012.bhudevnetworkvivah.com/uploads/favicon/favicon_1654940999.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },
  {
    id: 12,
    title: "Physical Vivah",
    description: "Physical.BhudevNetworkVivah.com",
    url: "https://physical.bhudevnetworkvivah.com",
    image: "https://physical.bhudevnetworkvivah.com/uploads/favicon/favicon_1644273591.jpg",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Categorical",
  },

  // City websites :

// Mumbai.BhudevNetworkVivah.com
// Amdavad.BhudevNetworkVivah.com
// Vadodara.BhudevNetworkVivah.com
// Surat.BhudevNetworkVivah.com
// Rajkot.BhudevNetworkVivah.com
// Gandhinagar.BhudevNetworkVivah.com
// Pune.BhudevNetworkVivah.com
// Bhavnagar.BhudevNetworkVivah.com
// Jamnagar.BhudevNetworkVivah.com 

  {
    id: 13,
    title: "Mumbai Vivah",
    description: "Mumbai.BhudevNetworkVivah.com",
    url: "https://mumbai.bhudevnetworkvivah.com",
    image: "https://mumbai.bhudevnetworkvivah.com/uploads/favicon/favicon_1633359613.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 14,
    title: "Amdavad Vivah",
    description: "Amdavad.BhudevNetworkVivah.com",
    url: "https://amdavad.bhudevnetworkvivah.com",
    image: "https://amdavad.bhudevnetworkvivah.com/uploads/favicon/favicon_1635169103.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 15,
    title: "Vadodara Vivah",
    description: "Vadodara.BhudevNetworkVivah.com",
    url: "https://vadodara.bhudevnetworkvivah.com",
    image: "https://vadodara.bhudevnetworkvivah.com/uploads/favicon/favicon_1640181376.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 16,
    title: "Surat Vivah",
    description: "Surat.BhudevNetworkVivah.com",
    url: "https://surat.bhudevnetworkvivah.com",
    image: "https://surat.bhudevnetworkvivah.com/uploads/favicon/favicon_1653139636.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 17,
    title: "Rajkot Vivah",
    description: "Rajkot.BhudevNetworkVivah.com",
    url: "https://rajkot.bhudevnetworkvivah.com",
    image: "https://rajkot.bhudevnetworkvivah.com/uploads/favicon/favicon_1631249757.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 18,
    title: "Gandhinagar Vivah",
    description: "Gandhinagar.BhudevNetworkVivah.com",
    url: "https://gandhinagar.bhudevnetworkvivah.com",
    image: "https://gandhinagar.bhudevnetworkvivah.com/uploads/favicon/favicon_1653975896.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 19,
    title: "Pune Vivah",
    description: "Pune.BhudevNetworkVivah.com",
    url: "https://pune.bhudevnetworkvivah.com",
    image: "https://pune.bhudevnetworkvivah.com/uploads/favicon/favicon_1633782321.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 20,
    title: "Bhavnagar Vivah",
    description: "Bhavnagar.BhudevNetworkVivah.com",
    url: "https://bhavnagar.bhudevnetworkvivah.com",
    image: "https://bhavnagar.bhudevnetworkvivah.com/uploads/favicon/favicon_1654255363.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  {
    id: 21,
    title: "Jamnagar Vivah",
    description: "Jamnagar.BhudevNetworkVivah.com",
    url: "https://jamnagar.bhudevnetworkvivah.com",
    image: "https://jamnagar.bhudevnetworkvivah.com/uploads/favicon/favicon_1631249757.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "City",
  },

  // add new categoreis here
  // Social Website

  {
    id: 23,
    title: "Bhudev Network",
    description: "BhudevNetwork.com",
    url: "https://bhudevnetwork.com",
    image: "https://bhudevnetwork.com/themes/wowonder/img/icon.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "Social",
  },
  {
    id: 24,
    title: "USA Vivah",
    description: "USA.BhudevNetworkVivah.com",
    url: "https://usa.bhudevnetworkvivah.com/",
    image: "https://usa.bhudevnetworkvivah.com/uploads/favicon/favicon_1696417619.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "NRI",
  },
  {
    id: 25,
    title: "Canada Vivah",
    description: "Canada.BhudevNetworkVivah.com",
    url: "https://canada.bhudevnetworkvivah.com/",
    image: "https://canada.bhudevnetworkvivah.com/uploads/favicon/favicon_1696414506.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "NRI",

  },
  // http://Aus-NZ.BhudevNetworkVivah.com
  {
    id: 26,
    title: "Australia Vivah",
    description: "Aus-NZ.BhudevNetworkVivah.com",
    url: "https://aus-nz.bhudevnetworkvivah.com/",
    image: "https://aus-nz.bhudevnetworkvivah.com/uploads/favicon/favicon_1696415436.png",
    downloadUrl: "/BhudevNetworkVivah.apk",
    category: "NRI",

  },

  

];

export default CardData;
function Footer(){
    return(
      <footer className="bg-gray-800 text-white py-8 mt-10">

          <p className="text-xs text-center">Copyright  2023 Bhudev Network Charitable Foundation. All rights reserved.</p>


    </footer>

    );
}

export default Footer;
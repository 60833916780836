import React, { useState } from "react";
import "./nav.css";
import contactNumbersData from "./contactNumbersData";
import Menu from "./nav-data";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
} from "react-router-dom";
import AboutPage from "./about-us";
import HomePage from "./home";
import GalleryPage from "./gallery";
import SocialMedia from "./pages/socialmedia";
import HappyStories from "./pages/happystories";
import ContactPage from "./contact.js";

const NavElements = () => {
  const location = useLocation();

  return Menu.map((SingleMenu, index) => {
    const isActive = location.pathname === SingleMenu.url;
    const baseClass =
      "pt-1 pb-1 pr-2 pl-2 font-semibold text-sm border border-gray-200 border-1 rounded hover:bg-orange-500 hover:text-white";
    const activeClass = isActive ? "bg-orange-300" : "";

    return (
      <li key={index} className={`${baseClass} ${activeClass}`}>
        <Link className="p-2" to={SingleMenu.url}>
          {SingleMenu.title}
        </Link>
      </li>
    );
  });
};

const NavElements2 = Menu.map((Single2Menu) => (
  <>
    <li className="border">
      <a
        className="p-3 flex w-full font-semibold text-sm hover:bg-orange-500 hover:text-white"
        href={Single2Menu.url}
      >
        {Single2Menu.title}
      </a>
    </li>
  </>
));

const ContactElements = contactNumbersData.map((SingleContact) => (
  <>
    <button
      className={`w-full text-xs flex gap-2 items-center ${SingleContact.bgColor} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`}
    >
      <svg
        className="h-6 w-6 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d={SingleContact.iconUrl}
        ></path>
      </svg>{" "}
      <a href={SingleContact.url}>{SingleContact.contact}</a>
    </button>
  </>
));

const SlideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between w-full content-center border border-gray">
        <div className="grid content-center fixed-width-2">
          <img src="/images/BCT.png" alt="logo" className="" />
        </div>
        <div className="">
          <button onClick={() => setIsOpen(!isOpen)} className="md:hidden p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-10 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </button>

          <div
            className={`fixed top-0 left-0 w-4/5 h-full bg-white transition-transform transform ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } md:relative md:translate-x-0 md:w-auto md:h-auto`} style={{ zIndex: 9999 }}
          >
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-0 right-0 p-4 md:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="w-full text-xl p-8 space-y-4 md:space-y-0 md:flex md:space-x-4 md:hidden">
              
                {NavElements2}
               
              
            </ul>
            <ul className="text-xl p-8 space-y-2 md:space-y-0 md:flex md:space-x-4">
              {ContactElements}
            </ul>
          </div>
        </div>
      </div>
      <div className="">
        <Router>
          <nav className="hidden lg:block">
            {/* without logo simple navigation menu using tailwind css */}
            <div className="">
              <ul className="flex w-full gap-1 justify-center lg:justify-start p-4">
                <NavElements />
              </ul>
            </div>
          </nav>

          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="*" element={<h1>Not Found</h1>} />
            <Route path="/social-media" element={<SocialMedia />} />
            <Route path="/happy-stories" element={<HappyStories />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default SlideMenu;


function AboutPage() {
  return (

      <div className="container mx-auto p-6">
            <h2 className="text-center text-2xl font-bold mb-4">About Us</h2>
            
            <section className="mb-4">
                <h3 className="text-xl font-semibold mb-2">Our Story</h3>
                <p className="text-gray-700">[Your organization's history, origins, and any relevant backstory]</p>
            </section>

            <section className="mb-4">
                <h3 className="text-xl font-semibold mb-2">Our Mission</h3>
                <p className="text-gray-700">[Your organization's mission and objectives]</p>
            </section>

            <section className="mb-4">
                <h3 className="text-xl font-semibold mb-2">Our Team</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* Team member cards */}
                    <div className="team-member-card p-4 shadow-lg rounded">
                        <img src="[Team member image URL]" alt="Name" className="w-full rounded mb-2"/>
                        <h4 className="text-lg font-semibold">[Team Member Name]</h4>
                        <p className="text-sm text-gray-600">[Position/Role]</p>
                    </div>
                    {/* Repeat for other team members */}
                </div>
            </section>

            {/* Other sections as needed */}
        </div>
    
      );
}

export default AboutPage;

import './css/header.css';
import SlideMenu from './slideMenu.js';

function Header() {

  return (
    <>
    <div className="flex justify-between">
      <SlideMenu />
    </div>
    </>
  );

}


export default Header;
const Menu = [
  {
    index : 0,
    title: 'Home',
    url: '/'
  },
  // {
  //   index : 1,
  //   title: 'About',
  //   url: '/about'
  // },
  // {
  //   index : 2,
  //   title: 'Gallery',
  //   url: '/gallery'
  // },
  // happy stories page
  {
    index : 3,
    title: 'Happy Stories',
    url: '/happy-stories'
  },
    {
    index : 4,
    title: 'Social Media',
    url: '/social-media'
  },
    // social media links page
  {
    index : 5,
    title: 'Contact',
    url: '/contact'
  },



];

export default Menu;